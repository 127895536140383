export default {
  CLASS: "Vehicle class",

  VEHICLE_A: "Small Car",
  VEHICLE_C: "Mid-Size Car",
  VEHICLE_E: "Full-Size Car",
  VEHICLE_F: "Luxury Car",
  VEHICLE_I: "Sporty Car",
  VEHICLE_K: "Pickup Truck",
  VEHICLE_N: "Small Crossover/SUV",
  VEHICLE_P: "Large Crossover/SUV",
  VEHICLE_R: "Small Luxury Crossover/SUV",
  VEHICLE_T: "Large Luxury Crossover/SUV",
  VEHICLE_V: "Compact Van",
  VEHICLE_W: "Minivan",
  VEHICLE_X: "Full-Size Van",

  VEHICLE_LONG_A: "Sub-Compact Car",
  VEHICLE_LONG_B: "Compact Car",
  VEHICLE_LONG_C: "Mid-Size Car",
  VEHICLE_LONG_E: "Full-Size Car",
  VEHICLE_LONG_F: "Near Luxury Car",
  VEHICLE_LONG_G: "Luxury Car",
  VEHICLE_LONG_H: "Prestige Luxury Car",
  VEHICLE_LONG_I: "Sporty Car",
  VEHICLE_LONG_J: "Premium Sporty Car",
  VEHICLE_LONG_K: "Small Pickup Truck",
  VEHICLE_LONG_M: "Full-Size Pickup Truck",
  VEHICLE_LONG_N: "Sub-Compact Crossover",
  VEHICLE_LONG_O: "Compact Crossover/SUV",
  VEHICLE_LONG_P: "Mid-Size Crossover/SUV",
  VEHICLE_LONG_Q: "Full-Size Crossover/SUV",
  VEHICLE_LONG_R: "Sub-Compact Luxury Crossover/SUV",
  VEHICLE_LONG_S: "Compact Luxury Crossover/SUV",
  VEHICLE_LONG_T: "Mid-Size Luxury Crossover/SUV",
  VEHICLE_LONG_U: "Full-Size Luxury Crossover/SUV",
  VEHICLE_LONG_V: "Compact Van",
  VEHICLE_LONG_W: "Minivan",
  VEHICLE_LONG_X: "Full-Size Van",
};
