export default {
  CUSTOMERS_LIST: "Customer list",
  ADD_CUSTOMER: "Add a client",
  CUSTOMER_ADDED: "Client added",
  EDIT_CUSTOMER: "Modify a customer",
  CUSTOMER_UPDATED: "Updated customer",
  DELETE_THIS_CUSTOMER: "Delete this customer?",
  CUSTOMER_DELETED: "Deleted customer",
  BILLING_INFORMATIONS_SAME_AS_CUSTOMER: "Same address as the customer",
  CUSTOMER_TYPE_INDIVIDUAL: "Particular",
  CUSTOMER_TYPE_COMPANY: "Business",
  VIEW_CUSTOMER: "client",
  CUSTOMER_INFORMATION: "Customer information",
  SELECT_CUSTOMER: "Select a customer",
  EXISTING_CUSTOMER: "Existing customer",
  ADD_EXISTING_CUSTOMER: "Add an existing customer",
  NEW_CUSTOMER: "new customer",
  SELECT_CUSTOMER: "Select a customer",
};
