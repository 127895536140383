export default {
  USERS_LIST: "Users list",
  ADD_USER: "Add a user",
  ADD_USERS: "Add users",
  USER_ADDED: "User added",
  IS_STAFF: "admin",
  ALL_IS_STAFF: "All types",
  AUTHORIZATIONS: "Authorizations",
  EDIT_USER: "Modify the user",
  USER_UPDATED: "Modified user",
  DELETE_THIS_USER: "Delete this user?",
  REMOVE_THIS_USER: "Remove this user?",
  USER_DELETED: "Deleted user",
  ACTIVE: "Active",
  DETACH: "To remove",
  ACTIVATE: "Enable",
  DEACTIVATE: "Disable",
  USER_ACTIVATED: "User activated",
  USER_DEACTIVATED: "User deactivated",
  RESTRICT_TO_LOCATIONS: "Restrict",
  CANNOT_SELF_DELETE: "Unable to delete the connected user",
  PASSWORD_COMPLEXITY:
    "The password must contain: figures, letters, capital letters, tiny, and make at least 8 characters.",
  VIEW: "User",
  SECURITY: "Security",
};
