export default {
  PURCHASES_ORDERS_LIST: "Command list",
  ADD_PURCHASES_ORDER: "Add a command",
  ADD_PURCHASES_ORDER_ITEM: "Line",
  PURCHASES_ORDER_ADDED: "Added command",
  OWNER: "Owner",
  EDIT_PURCHASES_ORDER: "Modify an order",
  PURCHASES_ORDER_UPDATED: "Update order",
  BILLING_ADDRESS: "Billing address",
  EXPIRATION_TIME: "Deadline",
  ITEM_TYPE: "type",
  SELECT_PRODUCTS: "Choose a product",
  SELECT_SUBSCRIPTIONS: "Choose a registration",
  SELECT_PACKAGES: "Choose a package",
  DELETE_PURCHASES_ORDER_ITEM: "Delete the line",
  UPDATE_PURCHASES_ORDER_ITEM: "Edit the line",
  PURCHASES_ORDER_ITEM_DELETED: "Deleted line",
  PURCHASES_ORDER_ITEM_ADDED: "Line added",
  PURCHASES_ORDER_ITEM_EDITED: "Modified line",
  SEND: "Send",
  CANCEL: "Cancel",
  REFUND: "Repay",
  ADD_PAYMENT: "Add a payment",
  PURCHASES_ORDER_SENT: "Order sent",
  SELECT_USERS: "Choose the user",
  SELECT_CUSTOMERS: "Choose",
  SELECT_ORGANIZATIONS: "Choose the Organization",
  SELECT_SUPPLIERS: "Choose the supplier",
  DELETE_THIS_PURCHASES_ORDER: "Delete this order?",
  PURCHASES_ORDER_DELETED: "Deleted command",
  CANCEL_PURCHASES_ORDER_QUESTION: "Cancel the order?",
  PURCHASES_ORDER_CANCELED: "Cancelled order",
  RECIPIENT: "RECIPIENT",
  ISSUER: "Issuer",
  VALIDATE: "To validate",
  PURCHASES_ORDER_VALIDATED: "Validated command",
  GENERATE_INVOICE: "Create the invoice",
  INVOICE_GENERATED: "Invoice creates",
  VIEW_INVOICE: "See the invoice",
  ADD_DELIVERY: "Add a delivery",
  DELIVERIES_STATE: "Delivery status",
  DELIVERIES_STATE_ITEM: "Product",
  DELIVERIES_STATE_ORDERED_QUANTITY: "Ordered quantity",
  DELIVERIES_STATE_DELIVERED_QUANTITY: "Delivered quantity",
  DELIVERIES_STATE_REMAINING_QUANTITY: "Quantity",
  DELIVERIES_STATE_REMAINING_QUANTITY: "Quantity",
  NOT_INVOICED: "Billing",
  NOT_DELIVERED: "To deliver",
  DELIVERED: "Livery",
  VIEW_PURCHASES_ORDER: "Purchase Order",
  PURCHASES_INVOICES: "Purchase invoices",
  ADD_INVOICE: "Invoice",
  INVOICING_TYPE: "Billing type",
  INVOICING_TYPE_COMPLETE: "Complete",
  INVOICING_TYPE_AMOUNT: "Amount",
  INVOICING_TYPE_PRODUCT: "Product",
  INVOICING_STATUS_PENDING: "Uncharted",
  INVOICING_STATUS_PARTIALLY_INVOICED: "Partially billed",
  INVOICING_STATUS_INVOICED: "Billed",
  DELIVERY_STATUS_PENDING: "Unreserved",
  DELIVERY_STATUS_PARTIALLY_DELIVERED: "Partially delivered",
  DELIVERY_STATUS_DELIVERED: "Livery",
  INVOICING_STATUS: "Status Facturation",
  ORDER_STATUS_DRAFT: "Draft",
  ORDER_STATUS_CANCELED: "Canceled",
  ORDER_STATUS_VALIDATED: "Validated",
  ORDER_STATUS_COMPLETED: "Supplemented",
  DELIVERY_STATUS: "Status Delivery",
  INVOICE_TYPE: "Invoice type",
  INVOICE_TYPE_DOWNPAYMENT: "Deposit invoice",
  INVOICE_TYPE_STANDARD: "Facture Standard",

  CREATED_AT: "Created at",
};
