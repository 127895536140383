export default {
  REQUESTS_LIST: "Request List",
  ADD_REQUEST: "Add Request",
  REQUEST_ADDED: "Request Added",
  EDIT_REQUEST: "Edit Request",
  REQUEST_UPDATED: "Request Updated",
  DELETE_THIS_REQUEST: "Delete this Request?",
  REQUEST_DELETED: "Request Deleted",
  EXPIRATION_TIME: "Expiration Date",
  REQUEST_TYPE: "Type",
  REQUEST_TYPE_INSPECTIONS: "Inspection",
  EXPIRATION_DATE: "Due Date",
  CREATOR: "Creator",
  REQUEST_STATUS_DRAFT: "Draft",
  REQUEST_STATUS_ASSIGNED: "Assigned",
  REQUEST_STATUS_APPROVED: "Approved",
  REQUEST_STATUS_COMPLETED: "Completed",
  REQUEST_STATUS_CANCELED: "Canceled",
  ADD_INSPECTION: "Add Inspection",
  ASSIGN_REQUEST: "Assign Request",
  ASSIGNED_TO: "Assigned To",
  ASSIGNED_AT: "Assigned On",
  COMPLETED_AT: "Completed On",
  INSPECTION: "Inspection",
  CANCEL_REQUEST: "Cancel Request",
  REQUEST_CANCELED: "Request Canceled",
  REQUEST_ASSIGNED: "Request Assigned",
  SHOW_INSPECTION: "View Inspection",
  VIEW_REQUEST: "View Request",
  COMPLETE_REQUEST: "Complete Request",
  REQUEST_COMPLETED: "Request Completed",
  ALL: "All Requests",
  ASSIGNED_TO_ME: "Assigned To Me",
  CREATED_BY_ME: "Created By Me",
  APPROVE_REQUEST: "Approve Request",
  APPROVED_AT: "Approved On",
  APPROVED_BY: "Approved By",
  ASSIGNED_BY: "Assigned By",
  CANCELED_BY: "Canceled By",
  CANCELED_AT: "Canceled On",
  ADD_RESPONSE_OBJECT: "Process Request",
  COMPLETED_BY: "Completed By",
  SHOW_RESPONSE_OBJECT: "View Result",
  VEHICLE_VIN: "Vehicle VIN",
  REQUEST_APPROVED: "Request Approved",
  VEHICLE_SKU: "Stock Number",
  REQUEST_PRIORITY_10: "Low",
  REQUEST_PRIORITY_20: "Medium",
  REQUEST_PRIORITY_30: "High",
  REQUEST_TYPE_EVALUATIONS: "Evaluation",
  REQUEST_TYPE_REPARATIONS: "Repair",
  ADD_QUICK_REQUEST: "Add Quick Request",

  ASSIGNED: "Assigned",
  COMPLETED: "Completed",
  CANCELED: "Canceled",
  CREATED_AT: "Created at",
  UPDATED_AT: "Updated at",
};
