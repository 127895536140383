export default {
  PURCHASES_DELIVERIES_LIST: "List of deliveries",
  ADD_PURCHASES_DELIVERY: "Add a delivery",
  ADD_PURCHASES_DELIVERY_ITEM: "Line",
  PURCHASES_DELIVERY_ADDED: "Added delivery",
  OWNER: "Owner",
  EDIT_PURCHASES_DELIVERY: "Modify a delivery",
  PURCHASES_DELIVERY_UPDATED: "Update delivery",
  BILLING_ADDRESS: "Billing address",
  EXPIRATION_TIME: "Deadline",
  ITEM_TYPE: "type",
  SELECT_PRODUCTS: "Choose a product",
  SELECT_SUBSCRIPTIONS: "Choose a registration",
  SELECT_PACKAGES: "Choose a package",
  DELETE_PURCHASES_DELIVERY_ITEM: "Delete the line",
  UPDATE_PURCHASES_DELIVERY_ITEM: "Edit the line",
  PURCHASES_DELIVERY_ITEM_DELETED: "Deleted line",
  PURCHASES_DELIVERY_ITEM_ADDED: "Line added",
  PURCHASES_DELIVERY_ITEM_EDITED: "Modified line",
  SEND: "Send",
  CANCEL: "Cancel",
  REFUND: "Repay",
  ADD_PAYMENT: "Add a payment",
  PURCHASES_DELIVERY_SENT: "Delivery sent",
  SELECT_USERS: "Choose the user",
  SELECT_CUSTOMERS: "Choose",
  SELECT_ORGANIZATIONS: "Choose the Organization",
  SELECT_SUPPLIERS: "Choose the supplier",
  DELETE_THIS_PURCHASES_DELIVERY: "Delete this delivery?",
  PURCHASES_DELIVERY_DELETED: "Delivery deleted",
  CANCEL_PURCHASES_DELIVERY_QUESTION: "Cancel delivery?",
  PURCHASES_DELIVERY_CANCELED: "Canceled delivery",
  RECIPIENT: "RECIPIENT",
  ISSUER: "Issuer",
  VALIDATE: "To validate",
  PURCHASES_DELIVERY_VALIDATED: "Validated delivery",
  VIEW_ORDER: "See the order",
  VIEW_PURCHASES_DELIVERY: "Purchase delivery",
  VALIDATE_DELIVERY: "Validate delivery",
  PURCHASES_ORDER_DELIVERY_ADDED: "Added delivery",

  CREATED_AT: "Created at",
};
