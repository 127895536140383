export default {
  CONTACTS_LIST: "Contacts list",
  ADD_CONTACT: "Add contact",
  CONTACT_ADDED: "Contact added",
  EDIT_CONTACT: "Update contact",
  CONTACT_UPDATED: "Contact mis a jour",
  DELETE_THIS_CONTACT: "Delete this contact?",
  CONTACT_DELETED: "Contact deleted",
  MISTER: "MR",
  MADAM: "MME",
  CONTACT_TYPE: "Contact type",
  TITLE_MR: "M.",
  TITLE_MME: "Mme",
  VIEW_CONTACT: "Contact",
  SAME_CONTACTS:
    'One or more contacts have already been recorded with the first name {FirstName} and the name {Lastname}.Does it act from the same person?Click on "Continue" to save if it is the case, otherwise click "Cancel".',
};
