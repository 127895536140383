export default {
  LOCATIONS_LIST: "List of locations",
  ADD_LOCATION: "Add a location",
  LOCATION_ADDED: "Location added",
  LOCATIONS_UPDATED: "Modified location",
  EDIT_LOCATION: "Modify this location",
  DELETE_THIS_LOCATION: "Remove this location?",
  LOCATION_DELETED: "Deleted location",
  VIEW_LOCATION: "emplacement",
};
