export default {
  RESELLER_SERVICES_LIST: "Services",
  ADD_RESELLER_SERVICE: "Add a service",
  RESELLER_SERVICE_ADDED: "Service added",
  EDIT_RESELLER_SERVICE: "Modify a service",
  RESELLER_SERVICE_UPDATED: "Updated service",
  DELETE_THIS_RESELLER_SERVICE: "Remove this service?",
  RESELLER_SERVICE_DELETED: "Deleted service",
  VIEW_RESELLER_SERVICE: "service",
};
