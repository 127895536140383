export default {
  RESELLER_INVOICES_LIST: "Invoice list",
  ADD_RESELLER_INVOICE: "Invoice",
  ADD_RESELLER_INVOICE_ITEM: "Line",
  RESELLER_INVOICE_ADDED: "Invoice added",
  OWNER: "Owner",
  EDIT_RESELLER_INVOICE: "Change an invoice",
  RESELLER_INVOICE_UPDATED: "Update",
  BILLING_ADDRESS: "Billing address",
  EXPIRATION_TIME: "Deadline",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choose a product",
  SELECT_SUBSCRIPTIONS: "Choose a registration",
  SELECT_PACKAGES: "Choose a package",
  DELETE_RESELLER_INVOICE_ITEM: "Delete the line",
  UPDATE_RESELLER_INVOICE_ITEM: "Edit the line",
  RESELLER_INVOICE_ITEM_DELETED: "Deleted line",
  RESELLER_INVOICE_ITEM_ADDED: "Line added",
  RESELLER_INVOICE_ITEM_EDITED: "Modified line",
  SEND: "Send",
  CANCEL: "Cancel",
  REFUND: "Repay",
  ADD_PAYMENT: "Add a payment",
  RESELLER_INVOICE_SENT: "Invoice sent",
  SELECT_USERS: "Choose the user",
  SELECT_CUSTOMERS: "Choose",
  SELECT_ORGANIZATIONS: "Choose the Organization",
  SELECT_SUPPLIERS: "Choose the supplier",
  DELETE_THIS_RESELLER_INVOICE: "Delete this invoice?",
  RESELLER_INVOICE_DELETED: "Invoice deleted",
  CANCEL_RESELLER_INVOICE_QUESTION: "Cancel the invoice?",
  RESELLER_INVOICE_CANCELED: "Invoice canceled",
  RECIPIENT: "RECIPIENT",
  ISSUER: "Issuer",
  VALIDATE: "To validate",
  RESELLER_INVOICE_VALIDATED: "Validated invoice",
  VIEW_ORDER: "See the order",
  SELECT_ORGANIZATION: "Choose",
  VIEW_RESELLER_INVOICE: "Facture",

  CREATED_AT: "Created at",
};
