export default {
  PRODUCTS_LIST: "Product List",
  ADD_PRODUCT: "Add Product",
  PRODUCT_ADDED: "Product Added",
  EDIT_PRODUCT: "Edit Product",
  PRODUCT_UPDATED: "Product Updated",
  DELETE_THIS_PRODUCT: "Delete this product?",
  PRODUCT_DELETED: "Product Deleted",
  SKU: "sku",
  DELETE_THIS_SUPPLIER_PRODUCT: "Delete this product? ",
  DELETE_THIS_WAREHOUSE_PRODUCT: "Delete this product? ",
  PRODUCTS_SAVED: "Registered products ",
  ADD_SUPPLIER: "Add a supplier ",
  VIEW_PRODUCT: "Products ",
};
