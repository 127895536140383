export default {
  STOCK_MOVEMENTS_LIST: "List of stock movement",
  ADD_STOCK_MOVEMENT: "Add a stock movement",
  ADD_STOCK_MOVEMENT_ITEM: "Line",
  STOCK_MOVEMENT_ADDED: "Stock added",
  EDIT_STOCK_MOVEMENT: "Modify a stock movement",
  STOCK_MOVEMENT_UPDATED: "Updated stock movement",
  ITEM_TYPE: "type",
  SELECT_PRODUCTS: "Choose a product",
  DELETE_STOCK_MOVEMENT_ITEM: "Delete the line",
  UPDATE_STOCK_MOVEMENT_ITEM: "Edit the line",
  STOCK_MOVEMENT_ITEM_DELETED: "Deleted line",
  STOCK_MOVEMENT_ITEM_ADDED: "Line added",
  STOCK_MOVEMENT_ITEM_EDITED: "Modified line",
  CANCEL: "Cancel",
  REFUND: "Repay",
  TYPE_ADD: "Add",
  TYPE_REMOVE: "Withdraw",
  TYPE_MOVE: "Move",
  TYPE_CORRECT: "To correct",
  VALIDATE: "To validate",
  CANCEL: "Cancel",
  STOCK_MOVEMENT_VALIDATED: "Validated stock movement",
  STOCK_MOVEMENT_CANCELED: "Stock movement canceled",
  CANCEL_STOCK_MOVEMENT_QUESTION: "Cancel a stock movement?",
  VIEW_STOCK_MOVEMENT: "Movement of stock",
  DELETE_THIS_STOCK_MOVEMENT_PRODUCT: "Delete this line?",
  DELETE_THIS_STOCK_MOVEMENT: "Remove this stock of stock?",
  STATUS_DRAFT: "Draft",
  STATUS_CANCELED: "Canceled",
  STATUS_VALIDATED: "Valid",
};
