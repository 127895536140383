export default {
  PURCHASES_PAYMENTS_LIST: "Payment list",
  ADD_PURCHASES_PAYMENT: "Add a payment",
  PURCHASES_PAYMENT_ADDED: "Payment",
  AUTHORIZATIONS: "Authorizations",
  EDIT_PURCHASES_PAYMENT: "Modify payment",
  PURCHASES_PAYMENT_SOURCE_MANUAL: "manuel",
  PURCHASES_PAYMENT_SOURCE_PAYPAL: "PayPal",
  PURCHASES_PAYMENT_SOURCE_STRIPE: "stripe",
  SOURCE: "source",
  TRANSACTION_ID: "idTransaction",
  TRANSACTION_DATA: "Transaction data",
  SOURCE_DETAILS: "Payment details",
  VIEW_INVOICE: "See the invoice",
  VIEW_PURCHASES_PAYMENT: "Purchase payment",
};
