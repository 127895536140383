export default {
  RESELLER_PRODUCTS_LIST: "Products List",
  ADD_RESELLER_PRODUCT: "Add a product",
  RESELLER_PRODUCT_ADDED: "Product added",
  EDIT_RESELLER_PRODUCT: "Modify a product",
  RESELLER_PRODUCT_UPDATED: "Updated product",
  DELETE_THIS_RESELLER_PRODUCT: "Delete this product?",
  RESELLER_PRODUCT_DELETED: "Deleted product",
  VIEW_RESELLER_PRODUCT: "Product",
};
