export default {
  ORGANIZATIONS_LIST: "Organization List",
  ADD_ORGANIZATION: "Add an Organization",
  ORGANIZATION_ADDED: "Organization Added",
  OWNER: "Owner",
  EDIT_ORGANIZATION: "Edit an Organization",
  ORGANIZATION_UPDATED: "Organization Updated",
  SELECT_A_PACKAGE: "Select a Package",
  ADD_SUBSCRIPTION: "Add a Subscription",
  SUBSCRIPTION_INVOICE_ADDED: "Subscription Invoice Added",
  AUTHORIZATIONS: "Authorizations",
  DELETE_THIS_ORGANIZATION: "Delete this Organization?",
  ORGANIZATION_DELETED: "Organization Deleted",
  VIEW_ORGANIZATION: "Organisation",

  CREATED_AT: "Created at",
  UPDATED_AT: "Updated at",
};
