export default {
  ROLES_LIST: "Roles list",
  ADD_ROLE: "Add a role",
  ROLE_ADDED: "Role added",
  ROLES_UPDATED: "Role updated",
  AUTHORIZATIONS: "Authorizations",
  EDIT_ROLE: "Edit role",
  DELETE_THIS_ROLE: "Delete this role?",
  ROLE_DELETED: "Role deleted",
  VIEW_ROLE: "Role",
};
