export default {
  FUEL_TYPES: "Fuel types",

  GAS: "Gas",
  HYBRID: "Hybrid",
  ELECTRIC: "Electric",
  PHEV: "Plug-in Hybrid Electric Vehicle",
  DIESEL: "Diesel",
  CNG: "Compressed natural gas",
};
