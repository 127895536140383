export default {
  OPTIONS_LIST: "Settings list",

  MODULE: "module",
  NAME: "nom",
  VALUE: "Value",
  MODULE_GENERAL: "General",
  MODULE_PURCHASES: "Purchases",
  MODULE_SALES: "Sales",
  OPTION_DOCUMENT_FOOTER: "Document page",

  OPTION_DEFAULT_TAXES_GROUP: "Default tax group",
  OPTIONS_DEFAULT_ESTIMATION_ITEM_HOURLY_RATE:
    "Default hourly rate of the estimate",
};
