export default {
  APP_NAME: "eliteManager",
  EMAIL: "E-mail",
  PASSWORD: "Password",
  SIGN_IN: "Connexion",
  FORGOT_PASSWORD: "Forgot your password?",
  PAGE_NOT_FOUND: "The requested page has not been found",
  RETURN_HOME: "Back to Home",
  WELCOME: "Welcome",
  MY_PROFILE: "My profile",
  LOGOUT: "Disconnection",
  DASHBOARD: "Dashboard",
  USERS: "Users",
  ROLES: "Roles",
  ORGANIZATIONS: "Organizations",
  ORGANIZATION: "Organization",
  MY_ORGANIZATION: "My organization",
  PRODUCTS: "Products",
  PRODUCT: "Product",
  ALL: "All",
  NAME: "Name",
  ADDRESS: "Address",
  PHONE: "Phone",
  CREATED_AT: "Created at",
  VALIDATED_AT: "Validated at",
  VALIDATE: "To validate",
  YES_VALIDATE: "Yes, validate",
  YES_ASSIGN: "Yes, assign",
  SEARCH: "Research",
  PER_PAGE: "By page",
  VIEW: "See",
  EDIT: "Modifier",
  DELETE: "DELETE",
  YES_DELETE: "Yes, delete",
  DISPLAY_FROM_X_TO_X_OF_X_ENTRIES: "Display of {from} to {to} on {of} entries",
  X_LINES_SELECTED: "{Count} selected lines",
  RETURN_TO_LIST: "Back to the list",
  SELECT_IMAGE: "Choose an image",
  CHANGE_IMAGE: "Change the image",
  REMOVE_IMAGE: "Delete image",
  ROLE: "Role",
  FIRSTNAME: "First name",
  LASTNAME: "Last name",
  CONFIRM_PASSWORD: "Confirm password",
  RESELLERS: "Resellers",
  RESELLER: "Dealer",
  LOCALE: "Language",
  LOCALE_FR: "French",
  LOCALE_EN: "English",
  GLOBAL: "Global",
  NONE: "None",
  UPDATED_AT: "Updated at",
  PERMISSIONS: "Permissions",
  ALL_ORGANIZATIONS: "All organizations",
  ALL_RESELLERS: "All resellers",
  ALL_ROLES: "All roles",
  ALL_USERS: "All the users",
  ALL_INSPECTIONS: "All inspections",
  ALL_PRODUCTS: "All products",
  EXCERPT: "Description",
  OWNER: "Owner",
  USER: "User",
  YES: "Oui",
  NO: "Non",
  OK: "OK",
  OR: "Or",
  PICTURE: "Photo",
  GALLERY: "Gallérie",
  TAXES: "Taxes",
  TAX_GROUP: "Tax group",
  TAX_GROUPS: "Tax groups",
  PRICE: "Prix",
  RATE: "Rate (%)",
  CODE: "Code",
  DEFAULT_TAXES: "Default taxes",
  INVOICES: "Invoices",
  INVOICE: "Facture",
  RECIPIENT: "RECIPIENT",
  COUNTRY: "Pays",
  COUNTRIES: "Pays",
  STATES: "Regions",
  STATE: "Region",
  CITY: "Ville",
  ZIPCODE: "Code postal",
  NOTE: "Note",
  STATUS: "Status",
  ALL_STATUSES: "All statutes",
  AMOUNT: "Amount",
  PAIED: "Paid",
  TOTAL: "Total",
  TOTAL_PAIED: "Total paid",
  CREATED_BY: "Created by",
  DETAILS: "Details",
  PAYMENTS: "Payments",
  UNIT_PRICE: "Unit price",
  SUBTOTAL: "Subtotal",
  QUANTITY: "Quantity",
  DISCOUNT: "Reduction",
  REMAINING_PAYMENT_AMOUNT: "balance",
  DISCOUNT_PERCENT: "Reduction (%)",
  CLOSE: "Close",
  CUSTOMER: "Client",
  CUSTOMERS: "Clients",
  ALL_CUSTOMERS: "All the clients",
  LOGS: "Newspapers",
  REQUEST_LOGS: "API call list",
  CAUSER: "auteur",
  DATE: "date",
  GO_BACK: "Back",
  PACKAGE: "Package",
  PACKAGES: "Package",
  ALL_PACKAGES: "All packages",
  SUBSCRIPTION: "inscription",
  SUBSCRIPTIONS: "inscriptions",
  TYPE: "type",
  TAX_TYPE_PERCENTAGE: "Percentage",
  TAX_TYPE_AMOUNT: "Amount",
  PAYMENT: "Payment",
  ALL_INVOICES: "All invoices",
  DISCOUNTS: "Discounts",
  DISCARD_CHANGES_AND_LEAVE_PAGE: "Abandon the changes and leave?",
  COMPANY_NAME: "Company Name",
  BILLING_CUSTOMER_TYPE_INDIVIDUAL: "Particular",
  BILLING_CUSTOMER_TYPE_COMPANY: "Business",
  REQUESTS: "Requests",
  REQUEST: "Request",
  INSPECTION: "inspection",
  INSPECTIONS: "inspections",
  INSPECTION_FORM: "Inspection form",
  INSPECTION_FORMS: "Inspection forms",
  ALL_INSPECTION_FORMS: "All inspection forms",
  EXPAND_ALL: "Unfold everything",
  COLLAPSE_ALL: "Fold everything",
  PREVIOUS: "Previous",
  NEXT: "Following",
  SUBMIT: "Submit",
  RESET: "Reset",
  BILLING_ENTITY_TYPE_INDIVIDUAL: "Particular",
  BILLING_ENTITY_TYPE_COMPANY: "Business",
  CONTACTS: "contacts",
  CONTACT: "contact",
  SUPPLIER: "Supplier",
  SCHOOL: "ecole",
  TITLE: "Title",
  TITLES: "Titles",
  ALL_TITLES: "All titles",
  TITLE_MR: "Sir",
  TITLE_MDM: "madame",
  TAG: "Tag",
  TAGS: "Labels",
  ADD_TAG: "Add a label",
  BIRTHDAY: "Birthday",
  SUPPLIERS: "Suppliers",
  FISCAL_NUMBER: "Tax number",
  ADD: "Add",
  ADDED: "Added",
  UPDATE: "Update",
  UPDATED: "Updated",
  COLOR: "Color",
  LINK_CONTACT: "Bind contact",
  CONTINUE: "Continue",
  ADD_CONTACT: "Add a contact",
  SOURCE: "Source",
  LOCATIONS: "Locations",
  LOCATION: "Location",
  ALL_LOCATIONS: "All locations",
  FILES: "Files",
  FILE: "File",
  ALL_FILES: "All the files",
  FOLDERS: "Folders",
  FOLDER: "Folder",
  ALL_FOLDERS: "All files",
  FILES_EXPLORER: "Explorer",
  WAREHOUSE: "Warehouse",
  WAREHOUSES: "Warehouses",
  PURCHASES_ORDER: "Purchase Order",
  PURCHASES_ORDERS: "Purchase orders",
  ISSUER: "Issuer",
  PURCHASES_INVOICE: "Purchase invoice",
  PURCHASES_INVOICES: "Purchase invoices",
  PURCHASES_DELIVERY: "Purchase delivery",
  PURCHASES_DELIVERIES: "Buying deliveries",
  PURCHASES_PAYMENT: "Purchase payment",
  PURCHASES_PAYMENTS: "Payment payments",
  SALES_ORDER: "Sales order",
  SALES_ORDERS: "Selling orders",
  SALES_INVOICE: "Sale",
  SALES_INVOICES: "Sale invoices",
  SALES_DELIVERY: "Sale delivery",
  SALES_DELIVERIES: "Sale deliveries",
  SALES_PAYMENT: "Sale payment",
  SALES_PAYMENTS: "Sale payments",
  BILLING_INFORMATIONS: "Billing information",
  BILLING_ADDRESS: "Billing address",
  RESELLER_PRODUCTS: "Reseller products",
  RESELLER_SERVICES: "Reseller services",
  RESELLER_INVOICE: "Reseller",
  RESELLER_INVOICES: "Reseller invoices",
  RESELLER_PAYMENTS: "Reseller payments",
  SERVICES: "Services",
  SERVICE: "Service",
  RESELLER_PRODUCT: "Reseller product",
  RESELLER_SERVICE: "Reseller service",
  APP: "Applications",
  APP_: "Applications",
  DELIVERY_ADDRESS: "Delivery address",
  OTHER_PHONES: "Other phones",
  EXTENSION: "Extension",
  ALL_SUPPLIERS: "All suppliers",
  PHONE_TYPE_MOBILE: "Mobile",
  PHONE_TYPE_HOME: "Home",
  PHONE_TYPE_OFFICE: "Office",
  PHONE_TYPE_BUSINESS: "Professional",
  PHONE_TYPE_FAX: "Fax",
  PHONE_TYPE_PAGER: "Biber",
  CONFIRMATION: "Confirmation",
  PHONE_TYPE_OTHER: "Other",
  SELLING_PRICE: "Selling price",
  BUYING_PRICE: "Purchase price",
  DESTINATION_WAREHOUSE: "Ward",
  SOURCE_WAREHOUSE: "Source warehouse",
  STOCK_MOVEMENT: "Movement of stock",
  STOCK_MOVEMENTS: "Stock movements",
  EXPECTED_QUANTITY: "Quantity expected",
  DELIVERED_QUANTITY: "Delivered quantity",
  SAVE: "Save",
  SUCCESS_VERIFICATION:
    "Congratulations !You have managed to check your security code.",
  ERROR_VERIFICATION: "The verification code is wrong",
  VERIFICATION_CODE: "Verification code",
  DELIVERY_INFORMATIONS: "Delivery information",
  RESEND_CODE: "Return the code",
  ENABLE_2FA:
    "Would you like to activate two-step verification to further secure your account?",
  DISABLE_2FA:
    "Would you like to deactivate the verification in two steps and return to the connection without 2FA?",
  IMPORTS: "Imports",
  IMPORT: "Import",
  MY_LOCATIONS: "My locations",
  NEXT_STEP: "Next step",
  PREV_STEP: "Previous step",
  CANCEL: "Cancel",
  YES_CANCEL: "Yes, cancel",
  DOWNLOAD_CSV_FILE: "Download the CSV file",
  RETURN_TO_LOGIN: "Back to connection",
  RESET_PASSWORD_SENT: "An e-mail containing a password reset link was sent.",
  RESET_PASSWORD_USER_NOT_FOUND:
    "We do not find a user with this email address.",
  SEND_PASSWORD_RESET_LINK: "Send the password reset link",
  RESET_PASSWORD: "Reset password",
  PASSWORD_UPDATED: "Updated password",
  TOKEN_ERROR: "Token of invalid password change thank you for trying again",
  EMAIL_ERROR: "Email Invalide Please try again",
  VEHICLE: "Vehicle",
  VEHICLES: "Vehicles",
  ESTIMATE: "Estimation",
  ESTIMATION: "Estimation",
  ESTIMATES: "Estimations",
  ESTIMATIONS: "Estimations",
  VEHICLE_ESTIMATIONS_DOMAINS: "Authorized area for API calls",
  DOMAIN: "Domain",
  DOMAINS: "Domains",
  ALL_DOMAINS: "All the domains",
  IP_ADDRESS: "Address IP",
  VEHICLE_ESTIMATIONS: "Vehicle assessments",
  PICTURES: "Photos",
  REFRESH: "Update",
  PRIORITY: "Priority",
  MAIN_PICTURE: "Main photo",
  START_TYPING_CITY_REGION_COUNTRY:
    "Start by seizing the city, the region or the country to complete the address",
  MANAGER: "Administrator",
  COMMENTS: "Comments",
  NEW_COMMENT: "New comment",
  WRITE_COMMENT: "Write your comment",
  RESPONSE_READY: "Ready response",
  STATUS_CODE: "HTTP status code",
  ERROR: "Error",
  TODO: "To do",
  LOW: "Low",
  HIGH: "High",
  TO: "To",
  WHOLESALE: "wholesale",
  DETAIL: "Detail",
  COEFFICIENT: "Coefficient",
  BY: "Bu",
  ENABLE_DEBUG_MODE: "Activated Debug mode",
  REMINDER: "Reminder",
  PARTICULAR: "Particular",
  COMPANY: "Company",
  RESOURCE_ID: "Resource ID",
  THE_REQUESTS: "The requests",
  THE_INSPECTIONS: "The inspections",
  THE_REPAIRS: "The reparations",
  THE_INSPECTION_FORMS: "The inspection form",
  THE_VEHICLES: "The vehicle",
  THE_RESELLERS: "The dealer",
  THE_EVALUATIONS: "The estimate",
  THE_ESTIMATIONS: "The estimate",
  THE_REQUEST_LOGS: "The API call",
  THE_CONTACTS: "The contacts",
  THE_CUSTOMERS: "The customers",
  THE_SALES_ORDERS: "The sales order",
  THE_SALES_INVOICES: "The sales bill",
  THE_SALES_PAYMENTS: "Sales payment",
  THE_SALES_DELIVERIES: "Sales delivery",
  THE_SUPPLIERS: "Supplier",
  THE_PURCHASES_ORDERS: "Purchase order",
  THE_PURCHASES_INVOICES: "The purchase invoice",
  THE_PURCHASES_PAYMENTS: "Purchase payment",
  THE_PURCHASES_DELIVERIES: "Purchase delivery",
  THE_PRODUCTS: "The product",
  THE_STOCK_MOVEMENTS: "The stock movement",
  THE_WAREHOUSE: "Warehouse",
  THE_RESELLER_INVOICES: "The reseller invoice",
  THE_RESELLER_PAYMENTS: "The payment of a reseller",
  THE_RESELLER_PRODUCTS: "The reseller product",
  THE_RESELLER_SERVICES: "The reseller service",
  THE_PACKAGES: "The package",
  THE_SUBSCRIPTIONS: "The subscription",
  THE_LOCATIONS: "The location",
  THE_ORGANIZATIONS: "The organizations",
  THE_USERS: "The users",
  THE_ROLES: "The roles",
  THE_PERMISSIONS: "The permissions",
  THE_IMPORTS: "The import",
  LOADING: "Loading",
  END: "End",
  YES_END: "Yes, end",
  OTHERS: "Others",
  ALL_OF: "All of {name}",
  YES_START: "Yes, start",
  YES_TAKE_OVER: "Yes, take over",
  INTERNAL: "Internal",
  ADD_INTERNAL: "Add internal",
  APPROVER: "Approver",
  APPROVAL: "Approval",
  RESPONSIBLE: "Responsible",
  REMOVE: "Remove",
  IMPORTANT: "Important",
  SHOW: "Show",
  DRAFTS: "Drafts",
  IN_PROGRESS: "In progress",
  PENDING: "Pending",
  TODO: "To do",
  CREATOR: "Creator",
  ALL_USERS_WITH_ROLE: "All users with role: {name}",
  SEARCH_MANAGER: "Find a manager",
  CHANGE_MANAGER: "Change manager",
  ENTER_MANAGER_NAME: "Enter the manager name",
  SAVE_BTN: "Save",
  COUNTRY_CODE: "Country code",
  SELECT_COUNTRY: "Select country",
  PHONE_NUMBER: "Phone number",
  EXAMPLE: "Example",
  HIDDEN: "Hidden",
  LOAD_MORE: "Load more",
  VIEW_MORE: "View more",
  MECANIC: "Mechanic",
  OPTIONS: "Settings",
  DELETE: "Delete",
  COPIED: "Copied!!",
  REPAIR: "Réparation",
  REPAIRS: "Réparations",
  SHARE: "Share",
  SEND: "Send",
  DAYS: "Days",
  MODIFY: "Modification",
};
