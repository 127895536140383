export default {
  IMPORTS_LIST: "Import list",
  IMPORTS_PREVIEW: "See an overview",
  ADD_IMPORT: "Add an import",
  IMPORT_ADDED: "Imports added",
  EDIT_IMPORT: "Change an import",
  IMPORT_UPDATED: "Imports update",
  DELETE_THIS_IMPORT: "Delete this import?",
  IMPORT_DELETED: "Imports deleted",
  IMPORT_MODEL: "Model",
  CHOOSE_MODEL: "Choose an import model",
  IMPORT_NAME: "nom",
  IMPORT_FILE_SIZE: "File size",
  COLUMN_ROW_COUNT: "Import lines",
  SHOW_ROWS: "Import data",
  COUNT_CSV_ROWS: "Number of lines",
  IMPORT_PREVIEW_DATA: "Overview of the data to import",
  CSV_PREVIEW_DATA: "Overview of file data",
  FILE: "File",
  RUN_DRY_IMPORT: "testerL'import",
  RUN_IMPORT: "Launch the import",
  RESULTS_ON_X_LINES: "Results for a total of {linger} lines",
  SUCCESSES: "Success",
  FAILURES: "Chess",
  X_ROW: "ligne {line}",
  LINE: "ligne",
  DATA: "Given",
  ERROR: "Error",
  MODEL_ID: "Id",
  RESULTS: "Results",
  IMPORTED_ITEMS: "Imported objects",
  ITEM: "objet",
};
