export default {
  SOMETHING_WENT_WRONG: "An error has occurred",
  PAGE_NOT_ALLOWED: "You are not allowed to access this page",
  FILE_UPLOAD_ERROR: "Error when loading the file",
  NOT_FOUND_ERROR_WITH_ID:
    "{resource} {id} do not exist or do not have the right to access it",
  NOT_FOUND_ERROR: "The Resource does not exist",
  FORBIDDEN_ERROR:
    "You do not have permission to access or perform this action",
  UNAUTHORIZED_ERROR: "Please connect to the application",
  INTERNAL_SERVER_ERROR: "Oops something went wrong",
  ERRORS: {
    UNAUTHORIZED: "Unauthorized",
    FORBIDDEN: "Access denied",
    NOT_FOUND: "Not found or refused access",
    SERVER_ERROR: "Oups...",
  },
  NO_ORGANIZATION: "You don't have an organization",
};
