export default {
  SALES_PAYMENTS_LIST: "Payment list",
  ADD_SALES_PAYMENT: "Add a payment",
  SALES_PAYMENT_ADDED: "Payment",
  AUTHORIZATIONS: "Authorizations",
  EDIT_SALES_PAYMENT: "Modify payment",
  SALES_PAYMENT_SOURCE_MANUAL: "Manuel",
  SALES_PAYMENT_SOURCE_PAYPAL: "PayPal",
  SALES_PAYMENT_SOURCE_STRIPE: "stripe",
  SOURCE: "Source",
  TRANSACTION_ID: "Id Transaction",
  TRANSACTION_DATA: "Transaction data",
  SOURCE_DETAILS: "Payment details",
  VIEW_INVOICE: "See the invoice",
  VIEW_SALES_PAYMENT: "Payment",
};
