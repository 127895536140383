export default {
  SAVE: "Save",
  EVENT_CREATE: "Creation",
  EVENT_UPDATE: "modification",
  EVENT_DELETE: "suppression",
  SUBSCRIBE_TO_NOTIFICATIONS: "Subscribe to notifications",
  SUBSCRIPTIONS_SAVED: "Updated subscriptions",
  YOU_HAVE_X_UNREAD_NOTIFICATIONS:
    'You have <strong class = "Text-Primary"> {Count} </strong> Notes not read.',
  VIEW_ALL: "See notifications",
  X_AGO: "There is {Duration}",
  XCAUSER_HAS_XEVENT_XOBJECTNAME:
    "<strong>{causer}</strong>A {event} <strong>{objectName}</strong>",
  DESCRIPTION_EVENT_CREATED: "created",
  DESCRIPTION_EVENT_UPDATED: "amended",
  DESCRIPTION_EVENT_DELETED: "deleted",
  CAUSER: "source",
  OBJECT: "objet",
  NOTIFICATIONS_LIST: "List of notifications",
  READ: "read",
  UNREAD: "Unread",
  ALL: "All",
  MARK_AS_READ: "Mark as read",
  MARK_AS_UNREAD: "Mark as unread",
};
