export default {
  INSPECTIONS_LIST: "Inspection list",
  ADD_INSPECTION: "Add an inspection",
  INSPECTION_ADDED: "Inspection added",
  EDIT_INSPECTION: "Edit an inspection",
  INSPECTION_UPDATED: "Inspection updated",
  DELETE_THIS_INSPECTION: "Delete this inspection?",
  INSPECTION_DELETED: "Inspection deleted",
  EXPIRATION_TIME: "Expiration date",
  INSPECTION_TYPE: "Type",
  INSPECTION_TYPE_INSPECTIONS: "Inspection",
  EXPIRATION_DATE: "Deadline",
  CREATOR: "Creator",
  INSPECTION_STATUS_DRAFT: "Draft",
  INSPECTION_STATUS_CANCELED: "Canceled",
  INSPECTION_STATUS_COMPLETED: "Completed",
  INSPECTION_STATUS_ASSIGNED: "Assigned",
  INSPECTION_STATUS_APPROVED: "Approved",
  INSPECTION_STATUS_PENDING: "Pending",
  INSPECTION_STATUS_REPAIRING: "Repairing",
  INSPECTION_STATUS_IN_PROGRESS: "In progress",
  DO_INSPECTION: "Do the inspection",
  REDO_INSPECTION: "Redo the inspection",
  COMMENT: "Comment",
  IMAGES: "Images",
  CLOSE_INSPECTION: "Close the inspection",
  INSPECTION_SUMMARY: "Summary",
  NO_VALUE: "Not specified",
  TOTAL_POINTS: "Total points",
  CLOSE_INSPECTION_REQUEST: "Finalize the inspection request",
  SHOW_REQUEST: "View the request",
  CANCEL_INSPECTION: "Cancel the inspection",
  INSPECTION_CANCELED: "Inspection canceled",
  CANCEL_THIS_INSPECTION: "Do you want to cancel this inspection?",
  COMPLETE_INSPECTION: "Complete the inspection",
  INSPECTION_COMPLETED: "Inspection completed",
  VIEW_INSPECTION: "View inspection",
  NO_INSPECTION: "No inspection performed",
  SAVE_VEHICLE: "Save the vehicle",
  INSPECTION_DONE: "Inspection done",
  TYPE: "Type",
  NEXT_STEP: "Next step",
  ALL: "All",
  GENERATE_REPORT: "Generate a report",
  RESET_SECTION_FIELDS: "Reset",
  VALIDATE_ALL_SECTION_FIELDS: "All conform",
  ADD_COMMENT: "Add comment",
  ADD_PICTURES: "Add pictures",
  TO_REPLACE: "To replace",
  ERRORS_UNTOUCHED_FIELD_X: "The field {fieldName} must be specified",
  END_FORM: "Finish",
  APPROVED_AT: "Approved at",
  APPROVED_BY: "Approved by",
  ASSIGNED_BY: "Assigned by",
  CANCELED_BY: "Canceled by",
  CANCELED_AT: "Canceled at",
  COMPLETED_BY: "Completed by",
  ASSIGNED_TO: "Assigned to",
  ASSIGNED_TO_GROUP: "Assigned to group",
  ASSIGNED_AT: "Assigned at",
  ASSIGNED_TO_ME: "Assigned to me",
  CREATED_BY_ME: "Created by me",
  COMPLETED_AT: "Completed at",
  APPROVE_INSPECTION: "Approve the inspection?",
  APPROVE_THIS_INSPECTION: "Do you want to approve this inspection?",
  ASSIGN_THIS_INSPECTION: "Do you want to assign this inspection?",
  INSPECTION_APPROVED: "Inspection approved",
  ASSIGN_INSPECTION: "Assign the inspection",
  INSPECTION_ASSIGNED: "Inspection assigned",
  DEFINE_ACTION: "Define action",
  INSPECTION_ACTION_TO_REPLACE: "To replace",
  INSPECTION_ACTION_TO_REPAIR: "To repair",
  INSPECTION_ACTION_TO_SCHEDULE: "To schedule",
  INSPECTION_ACTION_TO_VERIFY: "To verify",
  INSPECTION_ACTION_TO_MONITOR: "To monitor",
  INSPECTION_ACTION_SEEPAGE: "Seepage",
  INSPECTION_ACTION_VIBRATIONS: "Vibrations",
  INSPECTION_ACTION_TO_RECIFY: "To rectify",
  INSPECTION_ACTION_RECOMMENDED_MAINTENANCE: "Recommended maintenance",
  BATTERY_CHARGE: "Charge",
  AMPS: "AMPS",
  VOLTS: "VOLTS",
  UNIT: "Unit",
  START_INSPECTION: "Start inspection",
  START_THIS_INSPECTION: "Do you want to start this inspection?",
  INSPECTION_STARTED: "Inspection started",
  STARTED_AT: "Started at",
  STARTED_BY: "Started by",
  TAKE_OVER_THIS_INSPECTION:
    "This inspection is in progress by {name}. \n Do you want to take it over?",
  TAKE_OVER_INSPECTION: "Take over the inspection",
  INSPECTION_TOOK_OVER: "Takeover <u>done</u>",
  STANDARD: "Standard",
  OFF_ROAD: "Off-road",
  SUPER_SPORT: "Super sport",
  SUMMER: "Summer",
  WINTER: "Winter",
  FOUR_SEASONS: "All seasons",
  TIRE_TYPE: "Type",
  TIRE_SEASON: "Season",
  INSPECTION_FORM: "Inspection form",
  INSPECTION_FORMS_SELECTOR: "Select the type of inspection",
  NOT_STANDARD: "Non-standard",
  RESET: "Reset",
  ALL_GOOD: "All conform",
  RESET_FIELD_X: "Reset field {field}",
  SET_GOOD_X: "Set field {field} as conform",
  INSPECTORS: "Inspectors",
  ADD_INSPECTOR: "Add an inspector",
  INSPECTION_HAS_UNTOUCHED: "Some fields have not been filled",
  FILL_FIELD: "Go fill",
  SIGN_AND_SEND: "Sign and Send",
  REMINDERS_ALERTS: "Have you checked the information to send?",
  MODIFY: "Modify",
  LEAVE_A_COMMENT: "Leave a comment",
  VEHICLE_SCORE: "Vehicle score",
  SCORE: "Scores",
  COMPLETED: "Completed",
  COMPLETE: "Complete",
  SIGN: "Sign",
  APPLY_SIGNATURE_AND_CONTINUE: "Apply your signature and continue",
  CLEAR: "Clear",
  SIGNATURE_REQUIRED: "Signature is required",
  COMPLETE_INFORMATIONS_SENT: "Information sent successfully",
  CREATE_ESTIMATE: "Create an estimate",
  IN_PROGRESS_WARNING: "This inspection is currently in progress.",
  SELECT_APPROVER: "Select an approver",
  ASSIGNE_TO_INSPECTORS: "Assign inspectors for the inspection",
  YOU_MUST_CHECK_ALL_BOXES: "You must check all items",

  INSPECTION_ALREADY_EXIST:
    "An inspection is already in progress for this vehicle",
  SHOW_INSPECTION: "View the inspection",

  MECHANICS: "Mechanics",
  OFFICIAL_MECHANIC: "Official mechanic",
  CODE: "# inspection",
  CREATED_AT: "Created at",
  ASSIGNED: "Assigned",
  CANCELED: "Canceled",
  UPDATED_AT: "Updated at",

  YES_TAKE_OVER: "Yes, take over the inspection",

  EXCERPT: "Instructions/comments",
  SHARED: "Shared",
};
