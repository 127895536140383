export default {
  MAKE_VEHICLE_ESTIMATION: "Make a Vehicle Estimation",
  ESTIMATE_VEHICLE: "Estimate a Vehicle",
  ESTIMATE_ESS: "ESS Estimation",
  ESTIMATE_BLACK_BOOK_DRILLDOWN: "Black Book Drilldown Estimation",
  ESTIMATE_BLACK_BOOK_RETAILS: "Detailed Retail Sales List",
  BLACK_BOOK: {
    DRILLDOWN: {
      RETAIL: "Black Book Estimation (Retail)",
      WHOLE: "Black Book Estimation (Wholesale)",
    },
    RETAILS: "Average Retail Sales",
  },

  ESTIMATE_BLACK_BOOK_DRILLDOWN_RETAIL_VALUE: "Retail Value (Black Book)",
  ESTIMATE_BLACK_BOOK_DRILLDOWN_WHOLESALE_VALUE: "Wholesale Value (Black Book)",
  ESTIMATE_ESS_RETAIL_AVG_VALUE: "ESS Average Retail Value",
  ESTIMATE_ESS_AVG_VALUE: "ESS Average Value",

  ESS: "ESS Estimation",

  ESS_OBJECT: {
    RETAIL: "ESS Estimation (Retail)",
    WHOLE: "ESS Estimation (Wholesale)",
  },

  AVG_DAYS_SALE:
    "Average Days on Market: ({avg} day) before sale | Average Days on Market: ({avg} day) before sale | Average Days on Market: ({avg} days) before sale",

  AVG: {
    WHOLE: "Average Wholesale Sales",
    RETAIL: "Average Retail Sales",
  },

  PERCENTS: {
    DRILLDOWN: {
      RETAIL: "Black Book Percentage (Retail)",
      WHOLE: "Black Book Percentage (Wholesale)",
    },
    RETAILS: "Average Retail Sales Percentage",
    ESS: {
      RETAIL: "ESS Percentage (Retail)",
      WHOLE: "ESS Percentage (Wholesale)",
    },
  },

  AGGRESSION_PERCENTAGE: "Aggression Percentage",
  CONCESSION_PROFIT: "Concession Profit",
  DESIRED_PROFIT: "Desired Profit",
  PRICE_VARIATION: "Price Variation",

  OFFICIAL_PRICE: "Official Price",
  LOW_OFFICIAL_PRICE: "Low Official Price",
  HIGH_OFFICIAL_PRICE: "High Official Price",

  OFFICIAL_TAXED_PRICE: "Official Taxed Price",
  LOW_OFFICIAL_TAXED_PRICE: "Low Official Taxed Price",
  HIGH_OFFICIAL_TAXED_PRICE: "High Official Taxed Price",

  ADD_TAXES: "Add Taxes",
  PRICE_RESULT: "Price Result",

  RATIO_PRICE_KM: "Price/KM Ratio",

  WHOLE: "Wholesale",
  RETAIL: "Retail",

  STATUS: "Status",
  STATUS_XCLEAN: "Excellent",
  STATUS_CLEAN: "Good",
  STATUS_AVG: "Average",
  STATUS_ROUGH: "Poor",

  PRICE: "Price",
  BASE_PRICE: "Base Price",
  MILEAGE_PRICE: "Mileage Price",
  REGIONAL_PRICE: "Regional Price",
  ADJUSTED_PRICE: "Adjusted Price",
  VEHICLE_KMS: "Vehicle Mileage",

  KMS: "Mileage",
  KMS_RATIO: "Mileage Ratio",
  ESTIMATE: "Estimate",
  ESTIMATES: "Estimates",
  KMS_MIN: "Min Mileage",
  KMS_MAX: "Max Mileage",
  KMS_AVG: "Avg Mileage",
  KMS_AVG_RATIO: "Avg Mileage Ratio",
  KMS_AVG_MIN: "Avg Min Mileage",
  KMS_AVG_MAX: "Avg Max Mileage",
  ESTIMATE_SOLD: "ESS Wholesale",
  ESTIMATE_SOLD_ACTIVE: "Current Price",
  ESTIMATE_SOLD_ESS: "ESS Retail",
  PROVINCE: "Province",
  VIN: "VIN",
  MAKE: "Make",
  MODEL: "Model",
  SERIES: "Series",
  MODEL_YEAR: "Year",
  STYLE: "Style",

  VEHICLE_ESTIMATE_BTN: "Evaluate",
  RETAILS: {
    PRICE_AVG: "Average Price",
    MILEAGE_AVG: "Average Mileage",
    SOLD_PRICE_AVG: "Average Sold Price",
    SOLD_MILEAGE_AVG: "Average Sold Mileage",
    ACTIVE_PRICE_AVG: "Average Active Price",
    ACTIVE_MILEAGE_AVG: "Average Active Mileage",
    VALID_PRICE_AVG: "Average Valid Price",
    VALID_MILEAGE_AVG: "Average Valid Mileage",
    VALID_RANGE: "Valid Price Range (30%)",
    COMBINED_SCORE_AVG: "Average Combined Score",
  },
  VALID: "Price within Range",
  STANDARDIZED_PRICE: "Standardized Price",
  STANDARDIZED_MILEAGE: "Standardized Mileage",
  COMBINED_SCORE: "Combined Score",
  WEIGHTED_PERCENTAGE: "Weighted Percentage",
  VRANK: "Rank",

  API: "Vehicle Estimation API",
  IFRAME: "Vehicle Estimation Iframe",
  IFRAME_COPY_BTN: "Copy",
  IFRAME_COPIED_TITLE: "Copied!",
  IFRAME_COPIED_MESSAGE: "The HTML code has been copied",

  FINAL_ESTIMATE: "Final Estimate",
  DELETE_THIS_REQUEST_LOG: "Delete this API call?",
  REQUEST_LOG_DELETED: "API call deleted",
  REQUEST_LOGS_LIST: "API Calls List",
  REQUEST_LOG: "API Call",
  TOTAL_REQUEST_LOG:
    "You have no API calls | You have a total of {total} API call | You have a total of {total} API calls",

  MIN_KMS: "Minimum Mileage",
  MAX_KMS: "Maximum Mileage",
  MIN_PRICE: "Minimum Price",
  MAX_PRICE: "Maximum Price",

  DAYS_ON_MARKET_AVG: "Average Days on Market",
  SOLD_DAYS_ON_MARKET_AVG: "Average Sold Days on Market",
  ACTIVE_DAYS_ON_MARKET_AVG: "Average Active Days on Market",

  SOLDS: "Sold",
  ACTIVES: "Active",
  PRICES: "Prices",
  MILEAGES: "Mileages",

  ALL_VEHICLES: "All Vehicles",
  ACTIVE_VEHICLES: "Active Vehicles",
  SOLD_VEHICLES: "Sold Vehicles",
  PRICE_AVG: "Average Prices",
  KMS_AVG: "Average KMs",
  DAY_AVG: "Average Day",

  IS_ACCIDENT_VEHICLE: "Accident Vehicle?",
  REPAIRS_COST: "Damage Cost",
  IS_TOTAL_LOSS: "Rebuilt?",

  VEHICLE_COUNT: "Vehicle Count",
  MEMBER_SHIP: "Membership",

  MSRP: "MSRP",
  WARRANTY: {
    BASIC: "Basic Warranty",
    POWERTRAIN: "Powertrain Warranty",
  },
};
