export default {
  WAREHOUSES_LIST: "Warehouse",
  ADD_WAREHOUSE: "Add a warehouse",
  WAREHOUSE_ADDED: "Warehouse added",
  EDIT_WAREHOUSE: "Modify a warehouse",
  WAREHOUSE_UPDATED: "Updated warehouse",
  DELETE_THIS_WAREHOUSE: "Remove this warehouse?",
  WAREHOUSE_DELETED: "Warehouse",
  ALLOW_NEGATIVE_INVENTORY: "Authorize a negative stock",
  ALLOW_UNREGISTERED_PRODUCTS: "Authorize unregistered products",
  VIEW_WAREHOUSE: "Warehouse",
};
