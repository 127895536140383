export default {
  FILES_LIST: "File list",
  ADD_FILE: "Add a file",
  ADD_FILES: "Add files",
  FILE_ADDED: "Added file",
  EDIT_FILE: "Modify a file",
  FILE_UPDATED: "Updated file",
  DELETE_FILE: "Delete the file",
  DELETE_THIS_FILE: "Delete this file?",
  FILE_DELETED: "Deleted file",
  FILE_TYPE: "File type",
  CHOOSE_FILE: "Choose a file",
  SIZE: "Size",
  DOWNLOAD: "Download",
  OBJECT: "Linked object",
  ALL_OBJECTS: "All linked objects",
  NEW_FOLDER: "New file",
  NEW_FILE: "New file",
  COPY: "copier",
  MOVE: "Move",
  REMOVE: "DELETE",
  ADD_FOLDER: "Back a backrest",
  FOLDER_ADDED: "Backrest added",
  FOLDER_UPDATED: "Modified folder",
  EDIT: "modifier",
  EDIT_FOLDER: "Modify the folder",
  DELETE_THIS_ITEM: "Delete these elements?",
  UPLOAD: "Download",
  SHARE: "restrictions",
  FOLDER: "dossier",
  FILE: "File",
  FILE_HISTORY: "File history",
  HISTORY: "Historical",
  FOLDER_EXISTS_WITH_SAME_NAME: "A file already exists with this name",
  FILE_EXISTS_WITH_SAME_NAME: "A file already exists with this name",
  FILE_EXISTS_WITH_SAME_NAME_X:
    "A file already exists with this name {Filename}",
};
