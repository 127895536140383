export default {
  DEFAULT_MODULE: "Application",
  ADMIN: "Administration",
  INSPECTPRO: "Inspecpros",
  VEHICLES: "Vehicles",
  CRM_MODULE: "Customers",
  SALES_MODULE: "Selles",
  PURCHASES_MODULE: "Buying",
  INVENTORY_MODULE: "Inventory",
  FILE_EXPLORER_MODULE: "File explorer",
  ADMIN_MODULE: "Administration",
  DASHBOARD: "Dashboard",
  RESELLER_MODULE: "Reseller",
  VEHICLES_MODULE: "Vehicles",
};
