export default {
  RESELLER_PAYMENTS_LIST: "Payment list",
  ADD_RESELLER_PAYMENT: "Add a payment",
  RESELLER_PAYMENT_ADDED: "Payment",
  AUTHORIZATIONS: "Authorizations",
  EDIT_RESELLER_PAYMENT: "Modify payment",
  RESELLER_PAYMENT_SOURCE_MANUAL: "Manuel",
  RESELLER_PAYMENT_SOURCE_PAYPAL: "PayPal",
  RESELLER_PAYMENT_SOURCE_STRIPE: "Stripe",
  SOURCE: "Source",
  TRANSACTION_ID: "Id Transaction",
  TRANSACTION_DATA: "Transaction data",
  SOURCE_DETAILS: "Payment details",
  VIEW_INVOICE: "See the invoice",
  VIEW_RESELLER_PAYMENT: "Facture",
  VIEW: "Payment",
};
