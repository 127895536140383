export default {
  SUPPLIERS_LIST: "Suppliers list",
  ADD_SUPPLIER: "Add supplier",
  SUPPLIER_ADDED: "Supplier added",
  EDIT_SUPPLIER: "Edit supplier",
  SUPPLIER_UPDATED: "Supplier updated",
  DELETE_THIS_SUPPLIER: "Delete this supplier?",
  SUPPLIER_DELETED: "Supplier deleted",
  BILLING_INFORMATIONS_SAME_AS_SUPPLIER: "Same address as the supplier",
  ADD_PRODUCT: "Add a product",
  VIEW_SUPPLIER: "Supplier",
};
